import ReactFullpage from '@fullpage/react-fullpage';
import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import Call from '../components/Call';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../../static/images/features/num_icon_hover.svg';

const Home = props => {
  const intro = props.data.intro;
  const site = props.data.site.siteMetadata;
  const features = props.data.features.edges;

  return (
    <Layout bodyClass="page-home home-layout-wrapper" isHome={true}>
      <SEO title={site.title} />
      <Helmet>
        <meta
          name="description"
          content="At Anuttacon, our ultimate goal is to create new, innovative, intelligent and deeply engaging virtual world experiences and AGI products harnessing the full potential of AI technology"
        />
      </Helmet>
      <div className="video-box">
        {/* <Layout bodyClass="page-home home-layout-wrapper home-layout-second" isHome={true}>

              <div className="video-box second-box">
                 <div className="second-background video-background">
                   <img className="second-background-img" src={intro.frontmatter.second_bg} />
                 </div>
                <div className="second-layer">
                   <div className="second-title">
                     <div className="second-title-text">Why us</div>
                     <div className="indicator"></div>
                   </div>
                  <div className="row gap-4">
                     {features.map(({ node }, index) => (
                      <div key={node.id} className="value-card col-12 col-md-6">
                        <div className="feature">
                          <div>
                            {node.image && (
                              <div className="feature-image">
                                <img src={node.image.url} />
                              </div>
                            )}
                            <div className="feature-title">{node.title}</div>
                          </div>
                          <div className="w-100">
                            <div className="feature-content">{node.description}</div>
                            <div className="number-content">
                              <div className="number-item">{`0${index + 1}`}</div>
                              <Icon className="num-icon"></Icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="next-btn" onClick={() => fullpageApi.moveSectionUp()}>
                <div className="line-ver"></div>
                <div className="mouse-image">
                  <div className='mouse-icon'></div>
                </div>
                <div className="page-num-wrap">
                  <img className="arrow-image arrow-top" src={intro.frontmatter.arrow_image} />
                  <span className="page-num">02</span>
                  <img className="arrow-image arrow-bottom" src={intro.frontmatter.arrow_image} />
                </div>
              </div>
            </Layout> */}
        {/* <video className="video-background" preload="auto" loop playsInline autoPlay muted src="https://webstatic.mihoyo.com/upload/op-public/2021/08/12/c7f405d6cbb03e5c668a12d3e0de290a_2683598109381103734.mp4" tabIndex="-1"></video> */}
        <picture>
          <source srcSet={intro.frontmatter.intro_webp_bg}  type="image/webp" />
          <img className="video-background" src={intro.frontmatter.intro_bg} />
        </picture>
      
        <div className="layer">
          <div className="content-intro intro">
            <div className="container">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
              <Call showButton />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    intro: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        image
        intro_bg
        intro_webp_bg
        second_bg
        mouse_image
        arrow_image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
    features: allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image {
            url
          }
          num_icon
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
